import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../component/SEO/seo"
import Layout from "../component/layout"
import WelcomeMessage from "../component/welcome"
import LastFiveBlogPosts from "../component/recent-blogs"
import { colors, breakpointsMapMax as mediaQuery } from "../styles/theme"

const IntroContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
`

const AboutMe = styled.div`
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;

  ${mediaQuery[1]} {
    font-size: 0.75rem;
  }

  ${mediaQuery[2]} {
    padding: 2rem;
  }

  // border: 1px solid red;

  a {
    background: linear-gradient(to bottom, ${colors.gold} 0%, ${colors.gold} 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 4px;
    color: #000;
    text-decoration: none;
    transition: background-size 0.2s;

    &:hover {
      background-size: 4px 50px;
    }
  }

  p {
    margin-bottom: 1rem;

    ${mediaQuery[1]} {
      margin-bottom: 0.5rem;
    }
  }
`

const LatestPosts = styled.div`
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;

  // border: 1px solid green;

  ${mediaQuery[1]} {
    font-size: 0.75rem;
  }

  ${mediaQuery[2]} {
    padding: 2rem 0.5rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: 600;

    ${mediaQuery[1]} {
      font-size: 0.75rem;
    }
  }

  ul {
    margin: 1rem;
    padding-left: 1rem;

    li {
      margin-bottom: 1rem;

      ${mediaQuery[1]} {
        margin-bottom: 0.5rem;
      }
    }
  }

  a {
    background: linear-gradient(to bottom, ${colors.gold} 0%, ${colors.gold} 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 4px;
    color: #000;
    text-decoration: none;
    transition: background-size 0.2s;

    font-size: 1.25rem;

    &:hover {
      background-size: 4px 50px;
    }

    ${mediaQuery[1]} {
      font-size: 0.75rem;
    }
  }
`

export default ({ data }) => {
  return (
    <Layout>
      <SEO />
      <WelcomeMessage>Hey There!</WelcomeMessage>
      <IntroContainer>
        <AboutMe>
          <p>
            I am a skilled senior .NET developer living in Bangalore, India. I spend most of my time creating amazing products with ASP.NET
            and ASP.NET Core at{" "}
            <a href="https://www.cisco.com/" target="_blank" rel="noopener noreferrer">
              Cisco
            </a>
            .
          </p>
          <p>
            Over the years, I have become obsessed with crafting beautiful pieces of software, that are elegant, robust, performant and
            stand the test of ever changing requirements.
          </p>
          <p>
            This is my personal site where I <Link to="/blog">write articles</Link> about things that interest me in the world of Web
            Development, .NET/.NET Core, Microservices, Cloud and other related topics.
          </p>
          <p>
            If you'd like to get in touch with me, choose your channel from <Link to="/contact">this page</Link>.
          </p>
          <p>Its always nice to get feedbaks, so if you like something you read here, please feel free to share it and let me know.</p>
        </AboutMe>
        <LatestPosts>
          <p>Latest Posts</p>
          <LastFiveBlogPosts />
        </LatestPosts>
      </IntroContainer>
    </Layout>
  )
}

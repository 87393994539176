import React from "react"
import { Link } from "gatsby"
import UsePosts from "../hooks/use-posts"

const ArticleSummary = ({ post }) => {
  return (
    <li>
      <Link to={post.slug}>{post.title}</Link>
    </li>
  )
}

const LastFiveBlogPosts = () => {
  const posts = UsePosts()
  const lastFivePosts = posts.slice(0, 5)

  return (
    <ul>
      {lastFivePosts.map((post) => (
        <ArticleSummary key={post.slug} post={post} />
      ))}
    </ul>
  )
}

export default LastFiveBlogPosts

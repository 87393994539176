import styled from "@emotion/styled"
import {colors, breakpointsMapMax as mediaQuery} from "../styles/theme"


const WelcomeMessage = styled.h2`
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.25rem;
    padding-top: 4rem;
    padding-bottom: 2.5rem;
    color: ${colors.textDark};
    border-bottom: 1px solid ${colors.textDark};
    margin-bottom: 2.5rem;

    ${mediaQuery[1]} {
        font-size: 1.5rem;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    ${mediaQuery[2]} {
        padding: 2rem;
    }
`;

export default WelcomeMessage;